<template>
  <div id="loading-bg">
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
import i18nData from '../i18n/i18nData'
export default {
  data () {
    return {
      userRolesDic: {
        'admin': 'admin',
        'head-of-fleet': 'team-leader',
        'finance': 'accounting',
        'manager': 'warehouse-agent',
        'customer-support': 'customer-support',
        'transporter': 'traveller',
        'merchant': 'merchant',
        'growth-hacker': 'growth-hacker',
        'fc-operations-supervisor': 'fc-operations-supervisor',
        'fc-receiving-agent': 'fc-receiving-agent',
        'fc-inbounding-agent':'fc-inbounding-agent',
        'fc-storing-agent': 'fc-storing-agent',
        'fc-packing-agent': 'fc-packing-agent',
        'fc-picking-agent': 'fc-picking-agent',
        'sales': 'sales',
        'ops-admin': 'ops-admin',
        'commercial-admin': 'commercial-admin'
      }
    }
  },
  methods: {
    handleLoginEvent (data) {
      this.$acl.change('guest')
      if (data.profile[`${process.env.VUE_APP_API_URL}/role`][0] === 'merchant' && !data.profile[`${process.env.VUE_APP_API_URL}/app_metadata`]['terms_conditions']) {
        this.$router.push('/ar/pages/terms-and-conditions').catch(() => {})
      } else {
        this.$store.dispatch('updateUserInfo', {
          displayName: data.profile.name,
          email: data.profile.email,
          photoURL: data.profile.picture,
          providerId: data.profile.sub.substr(0, data.profile.sub.indexOf('|')),
          uid: data.profile.sub,
          userRole: data.profile[`${process.env.VUE_APP_API_URL}/role`][0]
        })
        this.$acl.change(this.$store.state.AppActiveUser.userRole)
        if (this.$store.state.AppActiveUser.userRole === 'customer-agent' || this.$store.state.AppActiveUser.userRole === 'merchant-agent') {
          this.$router.push(`/${this.$cookies.get('language')}/${this.$store.state.AppActiveUser.userRole}/task-list`).catch(() => {})
        } else {
          this.$router.push(data.state.target || `/${this.$cookies.get('language')}/${this.userRolesDic[this.$store.state.AppActiveUser.userRole]}/dashboard`).catch(() => {})
        }
      }
    }
  },
  created () {
    if (!this.$cookies.get('language')) {
      this.$cookies.set('language', 'ar', '100y')
    }
    try {
      this.$auth.handleAuthentication()
        .then(() => {
        })
        .catch(error => {
          this.$vs.notify({
            color:'danger',
            title: i18nData[this.$i18n.locale]['Error'],
            text: error,
            position: 'top-center'
          })
        })
    } catch (error) {
      this.$vs.notify({
        color:'danger',
        title: i18nData[this.$i18n.locale]['Error'],
        text: i18nData[this.$i18n.locale]['Error. Please try again!'],
        position: 'top-center'
      })
    }
  }
}

</script>

<style lang="scss">
@import "../assets/css/loader.css";
</style>
